import axios, {CanceledError} from 'axios';
import {Dropdown, Popover} from 'bootstrap';
import {useCallback, useEffect, useState} from 'react';
import Zoom from 'react-medium-image-zoom';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import itemPhotoPlaceholder from '../../assets/images/photo_placeholder.png';
import Spinner from '../../components/Common/Spinner';
import {useTitlePrefixContext} from '../../context/TitleContext';
import {injectCsrf} from '../../utils/auth';
import {TIMEOUT, VALID_ITEM_IDENTIFIER_PATTERN} from '../../utils/const';
import {isEmpty} from '../../utils/object';
import {formatDate, formatError, truncate} from '../../utils/string';
import './DisputeCreation.scss';

export default function DisputeCreation() {
    const navigate = useNavigate();
    let {searchQueryParam} = useParams();

    const {setTitlePrefix} = useTitlePrefixContext();

    const [isReady, setIsReady] = useState(false);
    const [searchQuery, setSearchQuery] = useState(searchQueryParam);
    const [listings, setListings] = useState([]);
    const [selectedListing, setSelectedListing] = useState({});
    const [prevSelectedListing, setPrevSelectedListing] = useState({});
    const [isPartialCredit, setIsPartialCredit] = useState(false);
    const [isRefundToCard, setIsRefundToCard] = useState(false);
    const [creditAmount, setCreditAmount] = useState(0);
    const [creditAmountDirty, setCreditAmountDirty] = useState((0).toFixed(2));
    const [disputeReason, setDisputeReason] = useState('');
    const [disputeNote, setDisputeNote] = useState('');
    const [selectedTagIds, setSelectedTagIds] = useState(new Set([]));
    const [selectedThumbnailIdx, setSelectedThumbnailIdx] = useState(0);
    const [customerNoteDirty, setCustomerNoteDirty] = useState('');
    const [customerNoteIsEditable, setCustomerNoteIsEditable] = useState(false);
    const [customerNoteBeingSaved, setCustomerNoteBeingSaved] = useState(false);
    const [customerNoteHidden, setCustomerNoteHidden] = useState(true);
    const [listingCardsOverflow, setListingCardsOverflow] = useState(false);

    const searchQueryInputId = 'search-query-input';
    const reasonInputId = 'reason-input';
    const creditAmountInputId = 'amount-input';
    const partialCreditCheckboxId = 'partial-credit-checkbox';
    const partialCreditShortcutContainerId = 'partial-credit-shortcut-container';
    const listingCardsContainerId = 'listing-cards-container';
    const listingCardsWrapperId = 'listing-cards-wrapper';
    const listingCardClass = 'listing-card';
    const disputeFormId = 'dispute-form';
    const selectedImageContainerId = 'selected-image-container';
    const thumbnailWrapperId = 'thumbnail-wrapper';
    const thumbnailContainerId = 'thumbnail-container';
    const thumbnailClass = 'thumbnail';
    const leftArrowClass = 'carousel-prev-btn';
    const rightArrowClass = 'carousel-next-btn';
    const customerNoteContainerId = 'customer-note-container';
    const customerNoteInputId = 'customer-note-input';
    const controlBtnClass = 'control-btn';
    const selectedClass = 'selected';
    const disabledClass = 'disabled';
    const overflowClass = 'overflows';
    const beingDraggedClass = 'being-dragged';

    function resetState() {
        setIsReady(false);
        setListings([]);
        setSelectedListing({});
        setPrevSelectedListing({});
        setIsPartialCredit(false);
        setIsRefundToCard(false);
        setCreditAmount(0);
        setCreditAmountDirty((0).toFixed(2));
        setDisputeReason('');
        setDisputeNote('');
        setSelectedTagIds(new Set([]));
        setSelectedThumbnailIdx(0);
    }

    useEffect(() => {
        if (!VALID_ITEM_IDENTIFIER_PATTERN.test(searchQueryParam)) {
            toast.error('Invalid search query', {toastId: searchQueryParam});
            navigate(`/returns/new?query=${encodeURIComponent(searchQueryParam)}`, {replace: true});
            return;
        }

        if (/^\d+$/.test(searchQueryParam)) {
            setTitlePrefix(`Listing #${searchQueryParam} — processing a return`);
        } else if (/^VALPN/i.test(searchQueryParam)) {
            setTitlePrefix(`${searchQueryParam.toUpperCase()} — processing a return`);
        } else {
            setTitlePrefix(`Listing ${searchQueryParam} — processing a return`);
        }

        const abortController = new AbortController();
        axios({
            method: 'get',
            url: `/api/items/${searchQueryParam}/`,
            signal: abortController.signal,
        }).then((response) => {
            setListings(response.data);
            setSelectedListing(response.data[0]);
        }).catch((error) => {
            // noinspection JSUnresolvedVariable
            if (error instanceof CanceledError && error.config.signal.reason === 'Unmount') {
                console.debug('Fetch aborted due to an unmount');
            } else {
                console.error(error);
                toast.error(<>Listing lookup has failed:<br/>{formatError(error)}</>);
                navigate(`/returns/new?query=${encodeURIComponent(searchQueryParam)}`, {replace: true});
            }
        });
        setTimeout(() => abortController.abort('Timeout'), TIMEOUT);

        return () => {
            abortController.abort('Unmount');
            setTitlePrefix('');
        };
    }, [searchQueryParam, navigate, setTitlePrefix]);

    // Sets the selected listing upon the listing retrieval
    useEffect(() => {
        if (!listings) {
            return;
        }

        const selectedListingIsEmpty = isEmpty(selectedListing);
        const listingToSelect = (
            selectedListingIsEmpty
            ? listings[0]
            : (listings.find((listing) => listing['listingId'] === selectedListing['listingId']) || listings[0])
        );

        // Explicitly comparing references because that's sufficient here
        if (selectedListingIsEmpty || !Object.is(selectedListing, listingToSelect)) {
            setSelectedListing(listingToSelect);
        }
    }, [listings, selectedListing]);

    // Processes listing selection resetting/updating affected inputs
    useEffect(() => {
        const selectedListingIsEmpty = isEmpty(selectedListing);
        const prevSelectedListingIsEmpty = isEmpty(prevSelectedListing);
        if (selectedListingIsEmpty) {
            return;
        } else if (!prevSelectedListingIsEmpty && Object.is(selectedListing, prevSelectedListing)) {
            setIsReady(true);
            return;
        }

        const totalPriceStr = selectedListing['totalPrice'];
        const totalPriceFloat = parseFloat(totalPriceStr);
        const note = selectedListing['customer']['note'];

        if (prevSelectedListingIsEmpty) {
            setCreditAmount(totalPriceFloat);
            setCreditAmountDirty(totalPriceStr);
            setCustomerNoteDirty(note);
            setSelectedTagIds(new Set(
                selectedListing['availableTags']
                    .filter((tag) => tag['isSelectedByDefault'])
                    .map((tag) => tag['id'])
            ));
            setTimeout(() => {
                const reasonInput = document.getElementById(reasonInputId);
                if (reasonInput) {
                    reasonInput.focus();
                }
            }, 50);
        } else {
            const prevTotalPrice = parseFloat(prevSelectedListing['totalPrice']);
            const updatedAmount = (creditAmount < prevTotalPrice && creditAmount < totalPriceFloat)
                ? creditAmount
                : totalPriceFloat;
            setCreditAmount(updatedAmount);
            setCreditAmountDirty(updatedAmount.toFixed(2));
            setIsPartialCredit(updatedAmount < totalPriceFloat);

            if (selectedListing['customer']['username'] !== prevSelectedListing['customer']['username']) {
                setCustomerNoteDirty(note);
            }
        }

        setPrevSelectedListing(selectedListing);
        setIsReady(true);
    }, [selectedListing, prevSelectedListing, creditAmount]);

    const onSearchBtnClick = useCallback(() => {
        function focusAndSelect() {
            const searchQueryInput = document.getElementById(searchQueryInputId);
            searchQueryInput.focus();
            searchQueryInput.select();
        }

        if (!searchQuery) {
            focusAndSelect();
        } else if (!VALID_ITEM_IDENTIFIER_PATTERN.test(searchQuery)) {
            toast.error('Search query must be a listing ID, an auction-item number or a VALPN');
            focusAndSelect();
        } else if (searchQuery !== searchQueryParam) {
            resetState();
            navigate(`/returns/${searchQuery}`);
        }
    }, [searchQueryParam, searchQuery, navigate]);

    function onPartialCreditClick() {
        const totalPrice = parseFloat(selectedListing['totalPrice']);
        const shouldResetAmount = isPartialCredit;
        setIsPartialCredit(!isPartialCredit);

        if (shouldResetAmount) {
            setCreditAmount(totalPrice);
            setCreditAmountDirty(selectedListing['totalPrice']);
        } else {
            const amountInput = document.getElementById(creditAmountInputId);
            setTimeout(() => {
                amountInput.focus();
                amountInput.select();
            }, 50);
        }
    }

    function onPartialCreditShortcutClick(percent) {
        const totalPrice = parseFloat(selectedListing['totalPrice']);
        const minAmount = isRefundToCard ? Math.min(0.50, totalPrice) : 0.01;
        const amount = Math.max(Number((totalPrice / 100 * percent).toFixed(2)), minAmount);

        setIsPartialCredit(true);
        setCreditAmount(amount);
        setCreditAmountDirty(amount.toFixed(2));
    }

    function onRefundToCardClick() {
        const shouldValidateAmount = !isRefundToCard;
        setIsRefundToCard(!isRefundToCard);

        if (shouldValidateAmount) {
            const totalPrice = parseFloat(selectedListing['totalPrice']);
            const minAmount = Math.min(0.50, totalPrice);

            if (creditAmount < minAmount) {
                toast.error(`Refund amount cannot be less than $${minAmount.toFixed(2)}`);
                setCreditAmount(minAmount);
                setCreditAmountDirty(minAmount.toFixed(2));
            }
        }
    }

    function onAmountChange(event) {
        // Bypassing the amount validation and processing if the focus was lost due to a click on another amount-related
        // control element
        const relatedTarget = event.relatedTarget;
        if (relatedTarget) {
            const rTargetId = relatedTarget.id;
            const rTargetParentId = relatedTarget.parentElement ? relatedTarget.parentElement.id : null;
            const checkboxId = partialCreditCheckboxId;
            const rTargetIsCheckbox = rTargetId === checkboxId || relatedTarget.htmlFor === checkboxId;
            const rTargetIsShortcut = rTargetParentId === partialCreditShortcutContainerId;

            if (rTargetIsCheckbox || rTargetIsShortcut) {
                return;
            }
        }

        const totalPrice = parseFloat(selectedListing['totalPrice']);
        const minAmount = isRefundToCard ? Math.min(0.50, totalPrice) : 0.01;
        let parsedAmount = parseFloat(creditAmountDirty);
        parsedAmount = isNaN(parsedAmount) ? creditAmount : parsedAmount;

        if (parsedAmount >= totalPrice) {
            parsedAmount = totalPrice;
            setIsPartialCredit(false);
            toast.error(
                `Partial credit amount must be less than the item's total
                 ($${selectedListing['totalPrice']})`,
            );
        } else if (parsedAmount < minAmount) {
            parsedAmount = minAmount;
            toast.error(
                `${isRefundToCard ? 'Refund' : 'Credit'} amount cannot be less than $${minAmount.toFixed(2)}`
            );
        }

        setCreditAmount(parsedAmount);
        setCreditAmountDirty(parsedAmount.toFixed(2));
    }

    // Preventing losing focus via Tab key when the amount is invalid
    function onAmountTab(event) {
        if (event.key === 'Tab') {
            const parsedAmount = parseFloat(creditAmountDirty);
            const totalPrice = parseFloat(selectedListing['totalPrice']);
            if (isNaN(parsedAmount) || parsedAmount >= totalPrice || parsedAmount < 0.01) {
                event.preventDefault();
            }
        }
    }

    function onTagClick(tagId) {
        if (selectedTagIds.has(tagId)) {
            selectedTagIds.delete(tagId);
        } else {
            selectedTagIds.add(tagId);
        }
        // noinspection JSCheckFunctionSignatures
        setSelectedTagIds(new Set(selectedTagIds));
    }

    function onThumbnailClick(event) {
        const target = event.target.closest(`.${thumbnailClass}`);
        const thumbnail = target.querySelector('img');
        const imageContainer = document.getElementById(selectedImageContainerId);
        const image = imageContainer.querySelector('img');
        image.src = thumbnail.src;

        const thumbnails = document.querySelectorAll(`#${thumbnailContainerId} .${thumbnailClass}`);
        thumbnails.forEach((thumb) => {
            const isTarget = thumb === target;
            const isSelected = thumb.classList.contains(selectedClass);
            if (isTarget && !isSelected) {
                thumb.classList.add(selectedClass);
            } else if (!isTarget && isSelected) {
                thumb.classList.remove(selectedClass);
            }
        });
    }

    function onThumbnailArrowClick(isLeftArrow = true) {
        const thumbnailContainer = document.getElementById(thumbnailContainerId);
        const scrollBy = thumbnailContainer.getBoundingClientRect().width;
        thumbnailContainer.scrollBy({
            top: 0,
            left: isLeftArrow ? -scrollBy : scrollBy,
            behavior: 'smooth',
        });
    }

    function onThumbnailScroll() {
        const thumbnailWrapper = document.getElementById(thumbnailWrapperId);
        const thumbnailContainer = document.getElementById(thumbnailContainerId);
        const maxScrollLeft = thumbnailContainer.scrollWidth - thumbnailContainer.clientWidth;
        const maxScrollThreshold = 1;
        const scrollLeft = thumbnailContainer.scrollLeft;
        const leftArrow = thumbnailWrapper.querySelector(`.${leftArrowClass}`);
        const rightArrow = thumbnailWrapper.querySelector(`.${rightArrowClass}`);

        if (maxScrollLeft <= 0) {
            leftArrow.classList.add(disabledClass);
            rightArrow.classList.add(disabledClass);
            return;
        }

        if (scrollLeft === 0) {
            leftArrow.classList.add(disabledClass);
        } else {
            leftArrow.classList.remove(disabledClass);
        }

        if (scrollLeft >= maxScrollLeft || (maxScrollLeft - scrollLeft) <= maxScrollThreshold) {
            rightArrow.classList.add(disabledClass);
        } else {
            rightArrow.classList.remove(disabledClass);
        }
    }

    function onCustomerNoteEditBtnClick() {
        if (!customerNoteIsEditable && !customerNoteBeingSaved) {
            setCustomerNoteIsEditable(true);
            setTimeout(() => {
                const customerNoteInput = document.getElementById(customerNoteInputId);
                customerNoteInput.focus();
                customerNoteInput.setSelectionRange(-1, -1);
            }, 20);
        }
    }

    function onCustomerNoteDiscardBtnClick() {
        setCustomerNoteIsEditable(false);
        setCustomerNoteDirty(selectedListing['customer']['note']);
    }

    const saveCustomerNote = useCallback(async () => {
        if (customerNoteBeingSaved) {
            return;
        }

        setCustomerNoteIsEditable(false);
        if (customerNoteDirty === selectedListing['customer']['note']) {
            return;
        }

        setCustomerNoteBeingSaved(true);
        const username = selectedListing['customer']['username'];
        const originalCustomerNote = selectedListing['customer']['note'];
        // Updating the note for all entries of this customer among the retrieved listings
        setListings((prev) => prev.map((listing) => ({
            ...listing,
            customer: {
                ...listing['customer'],
                note: listing['customer']['username'] === username ? customerNoteDirty : listing['customer']['note'],
            },
        })));

        try {
            await toast.promise(
                axios(injectCsrf({
                    method: 'patch',
                    url: `/api/customers/${username}/`,
                    data: {
                        note: customerNoteDirty,
                    },
                    signal: AbortSignal.timeout(TIMEOUT),
                })),
                {
                    pending: 'Saving customer notes...',
                    success: 'Customer notes saved',
                    error: {
                        render({data}) {
                            return <>Failed to save customer notes:<br/>{formatError(data)}</>;
                        },
                    },
                },
            );
        } catch (error) {
            console.error(error);
            // Reverting the changes
            setListings((prev) => prev.map((listing) => ({
                ...listing,
                customer: {
                    ...listing['customer'],
                    note: listing['customer']['username'] === username
                        ? originalCustomerNote
                        : listing['customer']['note'],
                },
            })));
            setCustomerNoteIsEditable(true);
        } finally {
            setCustomerNoteBeingSaved(false);
        }
    }, [customerNoteBeingSaved, customerNoteDirty, selectedListing]);

    const onClickOutsideOfCustomerNote = useCallback(async (event) => {
        const target = event.target;
        const input = document.getElementById(customerNoteInputId);
        const controlBtnsArr = Array.from(document.querySelectorAll(
            `#${customerNoteContainerId} .${controlBtnClass}`,
        ));

        if (customerNoteIsEditable && target !== input && !controlBtnsArr.includes(target)) {
            await saveCustomerNote();
        }
    }, [customerNoteIsEditable, saveCustomerNote]);

    async function onSubmitBtnClick(event, submitAsDraft = false) {
        event.preventDefault();

        if (!document.getElementById(disputeFormId).reportValidity()) {
            return;
        } else if (selectedListing['invoiceStatus'] !== 'Paid') {
            toast.error('Cannot return unpaid items');
            return;
        } else if (selectedListing['disputeId']) {
            toast.error('This item has already been returned');
            return;
        } else if (selectedListing['isTooOldForDispute']) {
            const choice = window.confirm(
                `This item was bought on ${formatDate(selectedListing['endDt'])}`
                + ` (${selectedListing['daysSincePurchase']} days ago).\nAre you sure you want to continue?`,
            );
            if (!choice) {
                return;
            }
        }

        try {
            await toast.promise(
                axios(injectCsrf({
                    method: 'post',
                    url: `/api/items/${selectedListing['listingId']}/dispute/`,
                    data: {
                        reason: disputeReason,
                        note: disputeNote,
                        amountReimbursed: creditAmount,
                        refundRequested: isRefundToCard,
                        isDraft: submitAsDraft,
                        tags: Array.from(selectedTagIds),
                    },
                    signal: AbortSignal.timeout(TIMEOUT),
                })),
                {
                    pending: 'Processing...',
                    success: {
                        render({data}) {
                            return <>
                                Successfully processed the dispute. 
                                <a href={`/admin/store_credit/itemdispute/${data.data.id}/change/`}
                                    target="_blank"
                                    rel="noreferrer">
                                    View in admin
                                </a>
                            </>;
                        },
                    },
                    error: {
                        render({data}) {
                            return <>Failed to process the return:<br/>{formatError(data)}</>;
                        },
                    },
                },
            );
            navigate('/returns/new');
        } catch (error) {
            console.error(error);
        }
    }

    // Something fucks up default Bootstrap dropdown wiring, so we're taking over manually
    function toggleSplitBtnDropdown(event) {
        // Let's make sure nothing else is handling this click event
        event.preventDefault();
        event.stopPropagation();
        const target = event.target.closest(`.dropdown-toggle`);
        const dropdown = new Dropdown(target);
        dropdown.toggle();
    }

    const onListingCardClick = useCallback((event) => {
        // Covers an edge case when a click outside of editable customer note happens to be a click on a listing card
        // Ugly :<
        if (customerNoteIsEditable) {
            // noinspection JSIgnoredPromiseFromCall
            saveCustomerNote();
        }

        const target = event.target.closest(`.${listingCardClass}`);
        const newSelectedListingId = parseInt(target.dataset.listingId);
        if (newSelectedListingId === selectedListing['listingId']) {
            return;
        }

        const newSelectedListing = listings.find((listing) => listing['listingId'] === newSelectedListingId);
        if (newSelectedListing) {
            setSelectedListing(newSelectedListing);
        }
    }, [listings, selectedListing, customerNoteIsEditable, saveCustomerNote]);

    const handleListingCardsOverflow = useCallback(() => {
        const listingCardsContainer = document.getElementById(listingCardsContainerId);
        const exceedsClientWidth = listingCardsContainer.scrollWidth > listingCardsContainer.clientWidth;
        const containsOverflowClass = listingCardsContainer.classList.contains(overflowClass);

        if (exceedsClientWidth && !containsOverflowClass) {
            listingCardsContainer.classList.add(overflowClass);
        } else if (!exceedsClientWidth && containsOverflowClass) {
            listingCardsContainer.classList.remove(overflowClass);
        }

        if (listingCardsOverflow !== exceedsClientWidth) {
            setListingCardsOverflow(exceedsClientWidth);
        }
    }, [listingCardsOverflow]);

    function onEnterSimulateClick(event, useMouseDown = false) {
        if (event.key === 'Enter' || event.keyCode === 13) {
            if (useMouseDown) {
                event.target.dispatchEvent(new MouseEvent(
                    'mousedown',
                    {bubbles: true, cancelable: true, view: window},
                ));
                event.target.dispatchEvent(new MouseEvent(
                    'mouseup',
                    {bubbles: true, cancelable: true, view: window},
                ));
            } else {
                event.target.click();
            }
        }
    }

    function generateCreditBreakdown(creditId) {
        const breakdown = selectedListing['customer']['creditBreakdown']['byCreditId'][creditId];
        if (!breakdown) {
            console.error(`Missing breakdown for credit #${creditId}`);
            return 'missing';
        }

        let result = '';

        for (let i = 0; i < breakdown.length; i++) {
            const entry = breakdown[i];
            const type = entry['type'];
            if (type === 'AUTOMATIC_INVOICE_ADJUSTMENT') {
                result += (
                    `<b>$${entry['amount']}</b>:
                    <a href='https://vistaauction.com/Account/Invoice/${entry['invoiceId']}' class='external-link'
                    target='_blank' rel='noreferrer'>Invoice #${entry['invoiceId']}</a>`
                );
            } else if (type === 'MANUAL_ENTER') {
                result += `<b>$${entry['amount']}</b>: ${entry['comment']}`;
            } else if (type === 'UNSPENT') {
                result += `<b>$${entry['amount']}</b>: Unspent`;
            } else {
                console.error(`Unknown credit breakdown entry type: ${type}`);
            }

            if (i < breakdown.length - 1) {
                result += '<br/>';
            }
        }

        return result;
    }

    // Initializes Bootstrap popovers
    useEffect(() => {
        if (!isReady) {
            return;
        }

        const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        popoverTriggerList.map((popoverTriggerEl) => new Popover(popoverTriggerEl, {trigger: 'focus'}));
    }, [isReady, selectedListing]);

    // Detects Enter key presses on the search query input
    useEffect(() => {
        if (!isReady) {
            return;
        }

        const searchQueryInput = document.getElementById(searchQueryInputId);
        const handler = (event) => {
            if (event.key === 'Enter' || event.keyCode === 13) {
                searchQueryInput.blur();
                onSearchBtnClick();
            }
        };
        searchQueryInput.addEventListener('keydown', handler);

        return () => {
            searchQueryInput.removeEventListener('keydown', handler);
        };
    }, [isReady, onSearchBtnClick]);

    // Detects overflow and applies horizontal scroll if needed on window resize
    useEffect(() => {
        if (!isReady) {
            return;
        }

        window.addEventListener('resize', handleListingCardsOverflow);

        return () => {
            window.removeEventListener('resize', handleListingCardsOverflow);
        };
    }, [isReady, handleListingCardsOverflow]);

    // Detects overflow and applies horizontal scroll if needed on new listings
    useEffect(() => {
        if (!isReady) {
            return;
        }

        handleListingCardsOverflow();
    }, [isReady, listings, handleListingCardsOverflow]);

    // Attaches event listeners to listing cards if they started to overflow or new listings appeared
    useEffect(() => {
        if (!isReady) {
            return;
        }

        const listingCardsContainer = document.getElementById(listingCardsContainerId);
        const listingCardsWrapper = document.getElementById(listingCardsWrapperId);
        const listingCards = listingCardsContainer.querySelectorAll(`.${listingCardClass}`);
        const listingCardLinks = listingCardsContainer.querySelectorAll('a');
        const _overflows = listingCardsOverflow;
        const leftMouseBtn = 0;
        let isDown = false;
        let hasMoved = false;
        let startX = 0.0;
        let scrollLeft = 0.0;

        const onWrapperMousedown = (event) => {
            if (event.button === leftMouseBtn) {
                scrollLeft = listingCardsContainer.scrollLeft;

                if (_overflows) {
                    isDown = true;
                    startX = event.pageX - listingCardsContainer.offsetLeft;
                }
            }
        };
        const onCardMouseup = (event) => {
            if (event.button === leftMouseBtn && !hasMoved) {
                onListingCardClick(event);
            }
        };
        const onLinkClick = (event) => {
            if (event.button === leftMouseBtn) {
                event.preventDefault();
            }
        };
        const onLinkMouseup = (event) => {
            if (event.button === leftMouseBtn && !hasMoved) {
                event.stopPropagation();
                window.open(event.target.href, '_blank').opener = null;
                resetDragging();
            }
        };
        const onBodyMouseup = (event) => {
            if (event.button === leftMouseBtn && isDown) {
                resetDragging();
            }
        };
        const onBodyMouseleave = () => {
            if (isDown) {
                resetDragging();
            }
        };
        const onBodyMousemove = (event) => {
            if (isDown) {
                event.preventDefault();
                if (!document.body.classList.contains(beingDraggedClass)) {
                    document.body.classList.add(beingDraggedClass);
                }

                const x = event.pageX - listingCardsContainer.offsetLeft;
                const walk = x - startX;
                listingCardsContainer.scrollLeft = scrollLeft - walk;

                if (walk && !hasMoved) {
                    hasMoved = true;
                }
            }
        };
        const resetDragging = () => {
            isDown = false;
            hasMoved = false;
            startX = 0.0;
            scrollLeft = 0.0;
            document.body.classList.remove(beingDraggedClass);

            // Clearing potential unwanted selection
            if (window.getSelection) {
                window.getSelection().removeAllRanges();
            } else if (document.selection) {
                document.selection.empty();
            }
        };

        listingCardsWrapper.addEventListener('mousedown', onWrapperMousedown);
        listingCards.forEach((card) => {
            card.addEventListener('mouseup', onCardMouseup);
        });
        if (_overflows) {
            listingCardLinks.forEach((link) => {
                link.addEventListener('click', onLinkClick);
                link.addEventListener('mouseup', onLinkMouseup);
            });
            document.body.addEventListener('mouseup', onBodyMouseup);
            document.body.addEventListener('mouseleave', onBodyMouseleave);
            document.body.addEventListener('mousemove', onBodyMousemove);
        }

        return () => {
            listingCardsWrapper.removeEventListener('mousedown', onWrapperMousedown);
            listingCards.forEach((card) => {
                card.removeEventListener('mouseup', onCardMouseup);
            });
            if (_overflows) {
                listingCardLinks.forEach((link) => {
                    link.removeEventListener('click', onLinkClick);
                    link.removeEventListener('mouseup', onLinkMouseup);
                });
                document.body.removeEventListener('mouseup', onBodyMouseup);
                document.body.removeEventListener('mouseleave', onBodyMouseleave);
                document.body.removeEventListener('mousemove', onBodyMousemove);
            }
        };
    }, [isReady, listings, listingCardsOverflow, onListingCardClick]);

    // Detects clicks outside of customer note input textarea
    useEffect(() => {
        if (!isReady) {
            return;
        }

        document.body.addEventListener('click', onClickOutsideOfCustomerNote);

        return () => {
            document.body.removeEventListener('click', onClickOutsideOfCustomerNote);
        };
    }, [isReady, onClickOutsideOfCustomerNote]);

    if (!isReady) {
        return <Spinner/>;
    }

    return (
        <>
            <div id="dispute-panel" className="p-4 pe-0">
                <div id="search-group" className="mb-4">
                    <input type="text"
                           id={searchQueryInputId}
                           className="form-control"
                           placeholder="Listing ID, Auction-Item or VALPN"
                           value={searchQuery}
                           onChange={(e) => setSearchQuery(e.target.value.trim())}
                           tabIndex="1"/>
                    <i className="bi bi-search search-icon" onClick={onSearchBtnClick}></i>
                </div>
                <div id={listingCardsContainerId} className="mb-4">
                    <div id={listingCardsWrapperId}>
                        {listings.map((listing, i) => (
                            <div key={i}
                                 className={`${listingCardClass} ${listing['listingId'] === selectedListing['listingId'] ? selectedClass : ''}`}
                                 data-listing-id={listing['listingId']}
                                 onKeyDown={(e) => onEnterSimulateClick(e, true)}
                                 tabIndex={2 + i}>
                                <div className="card-row">
                                    <p className="valpn">
                                        <label>VALPN</label>
                                        <span>{listing['valpn'] || '—'}</span>
                                    </p>
                                    <p className="auction-item">
                                        <label>Auction-Item</label>
                                        <span>
                                            {listing['auctionNumber'] > 0 ? listing['auctionNumber'] : 'unk.'}
                                            -
                                            {listing['itemNumber'] > 0 ? listing['itemNumber'] : 'unk.'}
                                        </span>
                                    </p>
                                    <p className="customer">
                                        <label>Customer</label>
                                        <span className="one-line-max" title={listing['customer']['username']}>
                                            {listing['customer']['username']}
                                        </span>
                                    </p>
                                </div>
                                <div className="card-row">
                                    <p className="product">
                                        <label>Product</label>
                                        <span className="two-lines-max" title={listing['title']}>
                                            {listing['title']}
                                        </span>
                                    </p>
                                </div>
                                <div className="card-row">
                                    <p className="bid-price">
                                        <label>Bid Price</label>
                                        <span>${listing['price']}</span>
                                    </p>
                                    <p className="buyers-premium">
                                        <label>Buyer's Premium</label>
                                        <span>{parseFloat(listing['buyersPremiumPct'])}% (${listing['buyersPremium']})</span>
                                    </p>
                                    <p className="lot-fee">
                                        <label>Lot Fee</label>
                                        <span>${listing['lotFee']}</span>
                                    </p>
                                    <p className="tax">
                                        <label>Tax</label>
                                        <span>${listing['salesTax']}</span>
                                    </p>
                                    <p className="total">
                                        <label>Total</label>
                                        <span>${listing['totalPrice']}</span>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div id="dispute-split-panel">
                    <div id="dispute-form-container">
                        <div className="mb-4">
                            <label htmlFor={reasonInputId} className="form-label mb-0">
                                Reason
                            </label>
                            <input type="text"
                                   form={disputeFormId}
                                   list="predefined-reasons"
                                   id={reasonInputId}
                                   className="form-control"
                                   placeholder="Broken, wrong item etc."
                                   minLength="1"
                                   maxLength="1000"
                                   value={disputeReason}
                                   onChange={(e) => setDisputeReason(e.target.value)}
                                   tabIndex={2 + listings.length}
                                   required/>
                            <datalist id="predefined-reasons">
                                <option value="Missing (via worker)"></option>
                                <option value="Missing (via customer)"></option>
                                <option value="Over 5 days, warned"></option>
                                <option value="Inaccurate description"></option>
                                <option value="Not new"></option>
                                <option value="Damaged"></option>
                                <option value="Doesn't work"></option>
                                <option value="Wrong item"></option>
                                <option value="Wrong quantity"></option>
                                <option value="Incomplete"></option>
                                <option value="Purple"></option>
                            </datalist>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="amount-input" className="form-label d-block mb-0">
                                Credit amount
                            </label>
                            <div id="amount-input-group">
                                <div id="amount-input-wrapper" className="d-inline-block">
                                    <input type="number"
                                           form={disputeFormId}
                                           id={creditAmountInputId}
                                           className="form-control"
                                           min="0"
                                           max={selectedListing['totalPrice']}
                                           step="any"
                                           value={creditAmountDirty}
                                           onChange={(e) => setCreditAmountDirty(e.target.value)}
                                           onBlur={onAmountChange}
                                           onKeyDown={onAmountTab}
                                           disabled={!isPartialCredit}
                                           tabIndex={isPartialCredit ? 4 + listings.length : -1}
                                           required/>
                                </div>
                                <div className="d-inline-block ms-3">
                                    <div className="form-check mb-0">
                                        <input type="checkbox"
                                               form={disputeFormId}
                                               id={partialCreditCheckboxId}
                                               className="form-check-input"
                                               checked={isPartialCredit}
                                               onChange={onPartialCreditClick}
                                               onKeyDown={onEnterSimulateClick}
                                               tabIndex={3 + listings.length}/>
                                        <label htmlFor={partialCreditCheckboxId}
                                               className="form-check-label"
                                               tabIndex="-1">
                                            Partial credit
                                        </label>
                                    </div>
                                    <div className="form-check mb-0">
                                        <input type="checkbox"
                                               form={disputeFormId}
                                               id="refund-requested-checkbox"
                                               className="form-check-input"
                                               checked={isRefundToCard}
                                               onChange={onRefundToCardClick}
                                               onKeyDown={onEnterSimulateClick}
                                               tabIndex={5 + listings.length}/>
                                        <label htmlFor="refund-requested-checkbox" className="form-check-label">
                                            Refund to card
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div id={partialCreditShortcutContainerId}>
                                <span onClick={() => onPartialCreditShortcutClick(10)} tabIndex="-1">10%</span>
                                <span onClick={() => onPartialCreditShortcutClick(25)} tabIndex="-1">25%</span>
                                <span onClick={() => onPartialCreditShortcutClick(33)} tabIndex="-1">33%</span>
                                <span onClick={() => onPartialCreditShortcutClick(50)} tabIndex="-1">50%</span>
                            </div>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="note-input" className="form-label mb-0">
                                Note
                            </label>
                            <input type="text"
                                   form={disputeFormId}
                                   id="note-input"
                                   className="form-control"
                                   placeholder="Very upset, gave final warning etc."
                                   value={disputeNote}
                                   onChange={(e) => setDisputeNote(e.target.value)}
                                   maxLength="1000"
                                   tabIndex={6 + listings.length}/>
                        </div>
                        {selectedListing['availableTags'].length > 0 && (
                            <div>
                                <label className="form-label mb-0">
                                    Tags
                                </label>
                                <div id="tag-container">
                                    {selectedListing['availableTags'].map((tag, i) => (
                                        <span key={i}
                                              className={`tag ${selectedTagIds.has(tag['id']) ? 'selected' : ''}`}
                                              title={tag['description']}
                                              onClick={() => {onTagClick(tag['id'])}}
                                              onKeyDown={onEnterSimulateClick}
                                              tabIndex={7 + listings.length + i}>
                                            {tag['name']}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div>
                            <div id="submit-btn-group" className="btn-group">
                                <button type="button"
                                        form={disputeFormId}
                                        className="btn btn-primary"
                                        onClick={(e) => onSubmitBtnClick(e, false)}
                                        tabIndex={7 + listings.length + selectedListing['availableTags'].length}>
                                    Submit
                                </button>
                                <button type="button"
                                        className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={toggleSplitBtnDropdown}
                                        tabIndex={8 + listings.length + selectedListing['availableTags'].length}>
                                    <span className="visually-hidden">Toggle dropdown</span>
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <span className="dropdown-item"
                                              onClick={(e) => onSubmitBtnClick(e, true)}
                                              onKeyDown={onEnterSimulateClick}
                                              tabIndex="0">
                                            Submit as draft
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <form id={disputeFormId} className="d-none"></form>
                    </div>
                    <div id="item-details-container"
                         className={selectedListing['details']['media'].length > 4 ? 'scrollable' : ''}>
                        <div id="item-details-wrapper">
                            <div id="item-carousel">
                                <div id={selectedImageContainerId} className="mb-3">
                                    <Zoom zoomImg={{
                                        src: selectedListing['details']['media'].length > 0
                                            ? selectedListing['details']['media'][selectedThumbnailIdx].replace('fullsize', 'largesize')
                                            : itemPhotoPlaceholder,
                                    }}>
                                        <img src={selectedListing['details']['media'].length > 0
                                            ? selectedListing['details']['media'][selectedThumbnailIdx]
                                            : itemPhotoPlaceholder}
                                             width="300"
                                             height="300"
                                             alt=""/>
                                    </Zoom>
                                </div>
                                <div id={thumbnailWrapperId}>
                                    <span className={`carousel-btn ${leftArrowClass} ${disabledClass}`}
                                          onClick={() => onThumbnailArrowClick(true)}>
                                    </span>
                                    <div id={thumbnailContainerId} onScroll={onThumbnailScroll}>
                                        {selectedListing['details']['media'].length > 0
                                            ? selectedListing['details']['media'].map((url, i) => (
                                                <div key={i}
                                                     className={`${thumbnailClass} ${i === selectedThumbnailIdx ? selectedClass : ''}`}
                                                     onClick={() => setSelectedThumbnailIdx((i))}>
                                                    <img src={url} alt=""/>
                                                </div>
                                            ))
                                            : (
                                                <div className={thumbnailClass} onClick={onThumbnailClick}>
                                                    <img src={itemPhotoPlaceholder} alt=""/>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <span
                                        className={`carousel-btn ${rightArrowClass} ${selectedListing['details']['media'].length > 4 ? '' : disabledClass}`}
                                        onClick={() => onThumbnailArrowClick(false)}>
                                    </span>
                                </div>
                            </div>
                            <div id="item-details">
                                <p>
                                    <span className="label-group">
                                        <label>Product</label>
                                        {
                                            selectedListing['disputeId'] && (
                                                <a href={`/admin/store_credit/itemdispute/${selectedListing['disputeId']}/change/`}
                                                   className="label-badge badge-red"
                                                   title="View in admin"
                                                   target="_blank"
                                                   rel="noreferrer">
                                                    already returned
                                                </a>
                                            )
                                        }
                                        {
                                            !selectedListing['disputeId'] && selectedListing['isTooOldForDispute'] && (
                                                <span className="label-badge badge-yellow"
                                                      title={formatDate(selectedListing['endDt'])}>
                                                    too old
                                                </span>
                                            )
                                        }
                                    </span>
                                    <span className="two-lines-max" title={selectedListing['title']}>
                                        <a href={`https://vistaauction.com/Listing/Details/${selectedListing['listingId']}`}
                                           className="external-link"
                                           target="_blank"
                                           rel="noreferrer">
                                            {truncate(selectedListing['title'], 70)}
                                        </a>
                                    </span>
                                </p>
                                <p>
                                    <label>Description</label>
                                    <span className="two-lines-max"
                                          title={selectedListing['details']['description']}>
                                        {selectedListing['details']['description']}
                                    </span>
                                </p>
                                <p>
                                    <label>Category</label>
                                    <span className="one-line-max"
                                          title={selectedListing['details']['category']}>
                                        {selectedListing['details']['category']}
                                    </span>
                                </p>
                                <p>
                                    <label>Scanner</label>
                                    <span className="one-line-max"
                                          title={selectedListing['details']['scannerInitials']}>
                                        {selectedListing['details']['scannerInitials']}
                                    </span>
                                </p>
                                <p>
                                    <label>MSRP & Condition</label>
                                    <span>{selectedListing['details']['msrp']}, {selectedListing['details']['condition']}</span>
                                </p>
                                <p>
                                    <span className="label-group">
                                        <label>Invoice</label>
                                        {
                                            selectedListing['invoiceStatus'] === 'Paid'
                                                ? <span className="label-badge badge-green">paid</span>
                                                : <span className="label-badge badge-red">unpaid</span>
                                        }
                                    </span>
                                    <span>
                                        <a href={`https://vistaauction.com/Account/Invoice/${selectedListing['invoiceId']}`}
                                           className="external-link"
                                           target="_blank"
                                           rel="noreferrer">
                                            {selectedListing['invoiceId']}
                                        </a>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="customer-info-panel" className="p-4">
                <div id="customer-username-container" className="mb-3">
                    <h4 className="mb-1" title={selectedListing['customer']['username']}>
                        <strong>
                            {truncate(selectedListing['customer']['username'], 35)}
                        </strong>
                    </h4>
                    <a href={`/admin/store_credit/customer/${selectedListing['customer']['id']}/change/`}
                       target="_blank"
                       rel="noreferrer">
                        View in admin
                    </a>
                </div>
                <div id="customer-stats-container" className="mb-3">
                    <ul className="mb-0">
                        <li>
                            <strong>Remaining credit:</strong>
                             ${selectedListing['customer']['creditRemaining']}
                        </li>
                        <li>
                            <strong>Outstanding refunds:</strong>
                             ${selectedListing['customer']['refundsOutstanding']}
                        </li>
                        <li>
                            <strong>Returns:</strong>
                             {selectedListing['customer']['disputesTotal']} out
                            of {selectedListing['customer']['purchasesTotal']}
                             purchases ({selectedListing['customer']['disputePercentage']}%)
                        </li>
                        <li>
                            <strong>Returns (90 days):</strong>
                             {selectedListing['customer']['disputesInLast90Days']} out
                            of {selectedListing['customer']['purchasesInLast90Days']}
                             purchases ({selectedListing['customer']['disputePercentageInLast90Days']}%)
                        </li>
                    </ul>
                </div>
                <div id={customerNoteContainerId} className="mb-3">
                    <div className="control-group">
                        <span className="collapse-btn"
                              onClick={() => setCustomerNoteHidden((prev) => !prev)}>
                            [{customerNoteHidden ? '+' : '-'}]
                        </span>
                        <label htmlFor={customerNoteInputId} className="form-label fw-bold mb-0">
                            {customerNoteHidden ? '...' : 'Customer notes'}
                        </label>
                        <div className={customerNoteHidden ? 'd-none' : 'd-inline-block'}>
                            <span
                                className={`${controlBtnClass} edit-btn ${customerNoteIsEditable ? 'd-none' : ''} ${customerNoteBeingSaved ? 'greyed-out' : ''}`}
                                title="Edit"
                                onClick={onCustomerNoteEditBtnClick}>
                                ✏️
                            </span>
                            <span
                                className={`${controlBtnClass} save-btn ${customerNoteIsEditable ? '' : 'd-none'} ${customerNoteBeingSaved ? 'greyed-out' : ''}`}
                                title="Save"
                                onClick={saveCustomerNote}>
                                ✔️
                            </span>
                            <span
                                className={`${controlBtnClass} discard-btn ${customerNoteIsEditable ? '' : 'd-none'} ${customerNoteBeingSaved ? 'greyed-out' : ''}`}
                                title="Discard"
                                onClick={onCustomerNoteDiscardBtnClick}>
                                ✖️
                            </span>
                        </div>
                    </div>
                    <textarea id={customerNoteInputId}
                              className={`form-control ${customerNoteHidden ? 'd-none' : ''}`}
                              placeholder="Customer has no notes"
                              maxLength="1500"
                              value={customerNoteIsEditable ? customerNoteDirty : selectedListing['customer']['note']}
                              onClick={onCustomerNoteEditBtnClick}
                              onChange={(e) => setCustomerNoteDirty(e.target.value)}
                              readOnly={!customerNoteIsEditable}
                              disabled={customerNoteBeingSaved}>
                    </textarea>
                </div>
                <div id="customer-dispute-history-container">
                    <label className="form-label fw-bold mb-0">
                        Recent returns
                    </label>
                    <div id="customer-dispute-history-table-wrapper">
                        <table className={`table table-bordered ${selectedListing['customer']['disputes'].length === 0 ? 'empty' : ''}`}>
                            <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Identifier</th>
                                <th scope="col">Product</th>
                                <th scope="col">Price</th>
                                <th scope="col">Credit</th>
                                <th scope="col">Reason</th>
                                <th scope="col">Note</th>
                            </tr>
                            </thead>
                            <tbody>
                            {selectedListing['customer']['disputes'].length > 0
                                ? selectedListing['customer']['disputes'].slice(0, 5).map((dispute, i) => (
                                    <tr key={i}>
                                        <td>
                                            <a href={`/admin/store_credit/itemdispute/${dispute['id']}/change/`}
                                               title="View in admin"
                                               target="_blank"
                                               rel="noreferrer">
                                                {formatDate(dispute['disputedOn'])}
                                            </a>
                                            {dispute['isDraft'] && (
                                                <i className="bi bi-journal-text draft-icon" title="Draft"></i>
                                            )}
                                        </td>
                                        <td>
                                            <a href={`https://vistaauction.com/Listing/Details/${dispute['item']['listingId']}`}
                                               className="external-link"
                                               target="_blank"
                                               rel="noreferrer">
                                                {dispute['item']['auctionNumber'] > 0 ? dispute['item']['auctionNumber'] : 'unk.'}
                                                -
                                                {dispute['item']['itemNumber'] > 0 ? dispute['item']['itemNumber'] : 'unk.'}
                                            </a>
                                        </td>
                                        <td>
                                            <span className="three-lines-max" title={dispute['item']['title']}>
                                                {dispute['item']['title']}
                                            </span>
                                        </td>
                                        <td>
                                            ${dispute['item']['totalPrice']}
                                        </td>
                                        <td>
                                            {dispute['creditIssued'] && (
                                                <span className="credit-amount"
                                                      data-bs-toggle="popover"
                                                      data-bs-trigger="focus"
                                                      title="Credit breakdown"
                                                      data-bs-content={dispute['isDraft']
                                                          ? 'Credit will be issued after the draft status is removed'
                                                          : generateCreditBreakdown(dispute['creditIssued']['id'])}
                                                      data-bs-html="true"
                                                      tabIndex="0">
                                                    ${dispute['creditIssued']['amount']}
                                                </span>
                                            )}
                                            {dispute['refundIssued'] && !dispute['creditIssued'] && (
                                                <span className="credit-amount"
                                                      data-bs-toggle="popover"
                                                      data-bs-trigger="focus"
                                                      data-bs-content={dispute['isDraft']
                                                          ? 'Refund will be issued after the draft status is removed'
                                                          : 'This is a refund'}
                                                      tabIndex="0">
                                                    ${dispute['refundIssued']['amount']}
                                                </span>
                                            )}
                                            {!dispute['creditIssued'] && !dispute['refundIssued'] && (
                                                <span>-</span>
                                            )}
                                        </td>
                                        <td>
                                            <span className="three-lines-max" title={dispute['reason']}>
                                                {dispute['reason']}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="three-lines-max" title={dispute['note']}>
                                                {dispute['note'] || '—'}
                                            </span>
                                        </td>
                                    </tr>
                                ))
                                : (
                                    <tr>
                                        <td colSpan="7" className="text-center fst-italic">
                                            The customer has no returns
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
